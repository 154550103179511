import React from 'react';
// import { Auth, Hub } from 'aws-amplify'
import { Link } from 'react-router-dom'

import './NavBarComponent.css';

const SignInStatus = {
    None: 1, // not yet firgured out
    LoggedIn: 2,
    LoggedOut: 3,
    Error: 4
}

class SignInComponent extends React.Component {
    state = {
        loggedIn: SignInStatus.None
    }
    
    // componentDidMount() {
    //     Auth.currentAuthenticatedUser()
    //         .then(user => {
    //             if(user === null) {
    //                 this.setState({ loggedIn: SignInStatus.Error })
    //             }
    //             else {
    //                 this.setState({ loggedIn: SignInStatus.LoggedIn })
    //             }
    //         })
    //         .catch(() => this.setState({ loggedIn: SignInStatus.LoggedOut }));
        
    //     Hub.listen('auth', (data) => {
    //         const { payload } = data
    //         if (payload.event === 'signIn') {
    //             this.setState({ loggedIn: SignInStatus.LoggedIn })
    //         }
    //         if (payload.event === 'signOut') {
    //             this.setState({ loggedIn: SignInStatus.LoggedOut })
    //         }
    //     })
    // }

    logOut = (e) => {
        // Auth.signOut();
        e.preventDefault();
    }

    logIn = (e) => {
        // Auth.federatedSignIn();
        e.preventDefault();
    }

    render() {
        const signedInState = this.state.loggedIn;
        if(signedInState === SignInStatus.LoggedOut) {
            return <a href="#" onClick={this.logIn}> Log In </a>
        }
        else if(signedInState === SignInStatus.LoggedIn) {
            return <a href="#" onClick={this.logOut}> Log Out </a>
        }
        else if(signedInState === SignInStatus.LoggedIn) {
            return <a> Error </a>
        }
        else {
            return null
        }
    }
}

class NavBarComponent extends React.Component {

    render() {
        return(
            <div className="nav-bar">
                <Link className="title" to="/">Team.Vote</Link>
                <div style={{flexGrow: "1"}}> </div>
                <SignInComponent />
            </div>
        )
    }
}

export {NavBarComponent}
