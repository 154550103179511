import React from 'react';
import './App.css';

import { NavBarComponent } from './NavBarComponent';
import { DiscreteVotingComponent } from './DiscreteVotingComponent';
import { PrivacyPolicyComponent } from './PrivacyPolicyComponent';

import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";

// initializes firebase.
import {firebaseTeamvote} from './firebase-teamvote';


class App extends React.Component {
    render() {
        return (
        <Router>
            <div className="app">
                <NavBarComponent />
                <div className="app-body">
                <Switch>
                    <Route path="/privacy-policy">
                        <PrivacyPolicyComponent />
                    </Route>
                    <Route path="/:id" component={DiscreteVotingComponent} />
                    <Route path="/">
                        <p>WIP. For now, enter a number after the domain name to enter that session. E.g. team.vote/123</p>
                    </Route>
                </Switch>
                </div>
            </div>
        </Router>
        );
    }
}

export default App;
