import firebase from 'firebase';

var firebaseTeamvote = null;
if (window.location.hostname === "localhost") {

    const firebaseConfig = {
        // Point to the RTDB emulator running on localhost.
        // In almost all cases the ns (namespace) is your project ID.
        databaseURL: "http://localhost:9000?ns=teamvote-e4dca"
    }

    firebaseTeamvote = firebase.initializeApp(firebaseConfig);
    firebase.functions().useFunctionsEmulator("http://localhost:5001")

    // to clear the db:
    // firebase.database().ref().set(null);
} 
else {
    const firebaseConfig = {
        apiKey: "AIzaSyC1e_wCAPGFVKPsuh3G4fsdFlGBtrbYVyU",
        authDomain: "teamvote-e4dca.firebaseapp.com",
        databaseURL: "https://teamvote-e4dca.firebaseio.com",
        projectId: "teamvote-e4dca",
        storageBucket: "teamvote-e4dca.appspot.com",
        messagingSenderId: "894054713",
        appId: "1:894054713:web:8ba3bff0633b55140eb8e8",
        measurementId: "G-1Z2X14YBZG"
    };
    
    firebaseTeamvote = firebase.initializeApp(firebaseConfig);
}

export {firebaseTeamvote}
