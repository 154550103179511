import React from 'react';
import Chart from "react-apexcharts";
import {hsvToRgb, rgbToHex} from './color-conversion'
import './DiscreteVotingComponent.css'

import { v4 as uuidv4 } from 'uuid';
import {firebaseTeamvote} from './firebase-teamvote';


const database = firebaseTeamvote.database();

function getOrCreateLocalUserId() {
    const item = localStorage.getItem('userId')
    if(item) { return item; }

    const uuid = uuidv4();
    localStorage.setItem('userId', uuid)
    return uuid
}

function getLocalUserName() {
    return localStorage.getItem('userName');
}

class ChartComponent extends React.Component {
    constructor(props) {
        super(props);
        this._isMounted = false;
    }

    componentDidMount()
    {
        this._isMounted = true;
        this.forceUpdate();
    }

    componentWillUnmount()
    {
        this._isMounted = false;
        this.forceUpdate();
    }

    // vary the colors by going across the hue range and varying te value. (saturation remains 1 for nice contrast)
    getColors = (numberOfColors) => {
        var colors = []
        for(var i = 0; i < numberOfColors; i++) {
            const hue = i/numberOfColors;
            const rgb = hsvToRgb(hue, 1, (i % 4)/8 + .5);
            colors.push(rgbToHex(...rgb));
        }
        return colors;
    }
}
class PieComponent extends ChartComponent {
    render() {
        if(!this._isMounted) {
            return null;
        }
        return (
            <Chart
                type="pie"
                options={{
                    dataLabels: {
                        enabled: true,
                        formatter: function (val, { seriesIndex, dataPointIndex, w }) {
                            var label = w.config.labels[seriesIndex];
                            val = Math.round(val*10)/10; // round to tenths place
                            return [label, val + "%"]
                        }
                    },
                    labels: this.props.keys,
                    colors: this.getColors(this.props.keys.length)
                }}
                series={this.props.values}
                height="100%"
            />
        )
    }
}
class BarComponent extends ChartComponent {
    render() {
        if(!this._isMounted) {
            return null;
        }
        return (
            <Chart
                type="bar"
                options={{
                    labels: this.props.keys,
                    colors: this.getColors(this.props.keys.length),
                    plotOptions: {
                        bar: {
                            columnWidth: '45%',
                            distributed: true
                        }
                    },
                    legend: {
                        show: false
                    },
                    xaxis: {
                        categories: this.props.keys,
                        labels: { style: {
                            colors: this.getColors(this.props.keys.length)
                        }}
                    },
                    yaxis: {
                        labels: {
                            show: false
                        }
                    },
                    chart: {
                        toolbar: {
                            show: false
                        }
                    }
                }}
                series={[{data: this.props.values}]}
                height="100%"
            />
        )
    }
}

class WaitingComponent extends React.Component {
    render() {
        return (
            <p className="waiting"> Waiting for everyone to vote... </p>
        )
    }
}

class DescriptionComponent extends React.Component {
    state = {
        editing: false,
        text: ""
    }

    editStart = () => {
        this.setState({text: this.props.text, editing: true})
    }

    editFinish = () => {
        this.props.onEdited(this.state.text);
        this.setState({editing: false})
    }
    
    handleChange = event => {
        this.setState({text: event.target.value});
    }

    render() {
        if(this.state.editing) {
            return (
                <div>
                    <textarea value={this.state.text} onChange={this.handleChange}/>
                    <button onClick={this.editFinish}>Finish</button>
                </div>
            )
        }
        else {
            return (
                <div>
                    <p> {this.props.text} </p>
                    <button onClick={this.editStart}>Edit</button>
                </div>
            )
        }
    }
}


class DiscreteVotingComponent extends React.Component {

    getOriginalState = () => {
        return {
            usersVotes: [],

            // category name to count
            categories: [
                '0 points',
                '.5 points',
                '1 points',
                '2 points',
                '3 points',
                '5 points',
                '8 points',
                '13 points',
                '20 points',
                '45 points',
                '100 points',
                '? points'
            ],

            // ordered with categories
            votes: [
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0
            ],
            description: ""
        }
    };

    state = this.getOriginalState()

    userRef = (userId) => {
        if(userId === undefined) {
            userId = getOrCreateLocalUserId();
        }
        return this.sessionRef().child('users').child(userId);
    }

    categoryVoted = (key, userId) => {
        this.userRef(userId).child('vote').set(key);
    }

    revealed = () => {
        if(this.state.usersVotes.length == 0) {
            return false;
        }
        for(var [name, id, vote] of this.state.usersVotes) {
            if(!vote) {
                return false;
            }
        }
        return true;
    }

    renderChart = () => {
        if(!this.revealed()) {
            return (
                <div className='waiting-holder'>
                    <WaitingComponent />
                </div>
            )
        }
        else {
            return (
                <div className='chart-holder'>
                    <BarComponent
                        keys={Array.from(this.state.categories)}
                        values={Array.from(this.state.votes)}
                    />
                </div>
            )
        }
    }


    // TODO: surely there's a more elegant way to write this.
    updateUsers = (users) => {
        if(!users)
        {
            return;
        }

        const usersVotes = Object.entries(users).map(([id, user]) => {
            if(user.vote)
            {
                return [user.name, id, user.vote]
            }
            else
            {
                return [user.name, id, null]
            }
        });

        const votesForCategory = (category) => {
            var i = 0
            for(const [userId, user] of Object.entries(users)) {
                if(user.vote === category) {
                    i += 1;
                }
            }
            return i
        }
        const categoryKeys = this.state.categories;
        
        this.setState({
            votes: categoryKeys.map(votesForCategory),
            usersVotes: usersVotes
        });
    }


    // prop ought to start with '/'
    sessionRef = () => {
        return database.ref('/sessions/' + this.getSessionId());
    }

    subscribeSession = () => {
        this.userRef().onDisconnect().remove();

        this.sessionRef().child('description').on('value', snapshot => {
            this.setState({description: snapshot.val()})
        })

        this.sessionRef().child('users').on('value', snapshot => {
            this.updateUsers(snapshot.val())
        })
    }

    getSessionId = () => {
        return this.props.match.params.id;
    }
    
    componentDidMount() {
        this.subscribeSession();
    }

    setDescription = desc => {
        this.sessionRef().child('description').set(desc);
    }

    setUserName = (name) => {
        this.setState({userName: name});
        this.userRef().child("name").set(name);
        
        localStorage.setItem('userName', name)
    };

    renderRequestName = () => {
        return (
            <div>
                <p>Please provide a user name</p>
                <input type="text" id="nameField" name="name" required maxLength="32" size="16" defaultValue={getLocalUserName() || ''}
                    onKeyPress={event => { if (event.key === 'Enter') {
                        const name = document.getElementById('nameField').value;
                        this.setUserName(name);
                    }}}/>
                
                <button onClick={()=>{
                        const name = document.getElementById('nameField').value;
                        this.setUserName(name);
                    }}>
                    Join Session
                </button>

            </div>
        )
    }

    showVotes = () => {
        for(var [name, id, vote] of this.state.usersVotes) {
            if(!vote) {
                this.categoryVoted('? points', id);
            }
        }
    }

    clearVotes = () => {
        const users = this.sessionRef().child('users');
        const clearVotePairs = this.state.usersVotes.map(([name, userId, userName]) => {
            return [userId + '/vote', null]
        })
        users.update(Object.fromEntries(clearVotePairs));
    }

    render() {
        if(!this.state.userName) {
            return this.renderRequestName();
        }

        const id = this.getSessionId();
        return (
            <div>
                <p> Topic { id } </p>
                <DescriptionComponent text={this.state.description} onEdited={this.setDescription}/>
                <br />

                {this.state.usersVotes.map( ([name, id, vote]) => {
                    var voteText = "<Waiting for vote>";
                    if(vote) {
                        voteText = "<Voted>"
                        if(this.revealed() || name == this.state.userName) {
                            voteText = vote;
                        }
                    }
                    return <p key={name}>{name} : {voteText}</p>
                })}

                {this.renderChart()}

                {this.state.categories.map( category => (
                    <button key={category} onClick={()=>{this.categoryVoted(category)}}>
                        {category}
                    </button>
                ))}
                <button onClick={()=>{this.showVotes()}}>
                    Show Votes
                </button>
                <button onClick={()=>{this.clearVotes()}}>
                    Clear Votes
                </button>
            </div>
        )
    }
}

export {DiscreteVotingComponent}
